import React from "react";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const checkGDPR = () => {
  const gdpr = typeof window !== "undefined" && localStorage.getItem("ll-gdpr");

  if (gdpr !== "1") {
    typeof document !== "undefined" && document.body.classList.add("freeze");
    return false;
  }
  return true;
};

const getTheme = () => {
  const theme =
    typeof window !== "undefined" && localStorage.getItem("ll-theme");

  if (!theme) {
    return "light";
  }
  if (theme === "dark") {
    typeof document !== "undefined" && document.body.classList.add("dark-mode");
  }
  return theme;
};

const initialState = {
  acceptedGDPR: checkGDPR(),
  theme: getTheme(),
  products:
    (typeof window !== "undefined" &&
      JSON.parse(localStorage.getItem("ll-basket"))) ||
    [],
};

const setLocalStorageCart = (products) => {
  typeof window !== "undefined" && localStorage.removeItem("ll-basket");
  typeof window !== "undefined" &&
    localStorage.setItem("ll-basket", JSON.stringify(products, undefined, 2));
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ACCEPT_GDPR": {
      document.body.classList.remove("freeze");

      localStorage.setItem("ll-gdpr", 1);

      return {
        ...state,
        acceptedGDPR: true,
      };
    }

    case "SET_THEME": {
      const { theme } = action;

      if (theme === "dark") {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }

      return {
        ...state,
        theme,
      };
    }
    case "TOGGLE_THEME": {
      const newTheme = state.theme === "light" ? "dark" : "light";

      typeof window !== "undefined" &&
        localStorage.setItem("ll-theme", newTheme);

      if (newTheme === "dark") {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }

      return {
        ...state,
        theme: newTheme,
      };
    }
    case "ADD_PRODUCT": {
      const { product: productToAdd } = action;
      let { products } = state;
      const existingProductIndex = products.findIndex(
        (p) => p.name === productToAdd.name
      );

      if (existingProductIndex > -1) {
        products[existingProductIndex].quantity += 1;
      } else {
        products = [...products, productToAdd];
      }

      setLocalStorageCart(products);

      return {
        ...state,
        products,
      };
    }
    case "EDIT_PRODUCT": {
      const { product: productToEdit, editAction } = action;
      let { products } = state;
      const productIndex = products.findIndex(
        (p) => p.name === productToEdit.name
      );

      if (editAction === "remove") {
        products[productIndex].quantity -= 1;
      } else {
        products[productIndex].quantity += 1;
      }

      setLocalStorageCart(products);

      return {
        ...state,
        products,
      };
    }
    case "REMOVE_PRODUCT": {
      let { products } = state;
      const productIndex = products.findIndex(
        (p) => p.name === action.productName
      );

      products.splice(productIndex, 1);

      setLocalStorageCart(products);

      return {
        ...state,
        products,
      };
    }
    case "CLEAR_PRODUCTS": {
      typeof window !== "undefined" && localStorage.removeItem("ll-basket");

      return {
        ...state,
        products: [],
      };
    }
    default:
      throw new Error("Bad Action Type");
  }
};

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
