exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedankt-js": () => import("./../../../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-bestellen-js": () => import("./../../../src/pages/bestellen.js" /* webpackChunkName: "component---src-pages-bestellen-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogpost-page-js": () => import("./../../../src/pages/blogpost-page.js" /* webpackChunkName: "component---src-pages-blogpost-page-js" */),
  "component---src-pages-category-page-js": () => import("./../../../src/pages/category-page.js" /* webpackChunkName: "component---src-pages-category-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-page-js": () => import("./../../../src/pages/product-page.js" /* webpackChunkName: "component---src-pages-product-page-js" */),
  "component---src-pages-verhaal-js": () => import("./../../../src/pages/verhaal.js" /* webpackChunkName: "component---src-pages-verhaal-js" */),
  "component---src-pages-winkelmand-js": () => import("./../../../src/pages/winkelmand.js" /* webpackChunkName: "component---src-pages-winkelmand-js" */)
}

